// General
import { isPending } from '@reduxjs/toolkit';
import format from 'date-fns/format';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// Hooks
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// UI-kit
import PageSectionHeader from 'ui-kit/page-section-header/page-section-header';

// Display components
import MyCaregiversCard from 'display-components/family-management/my-caregivers-card';

// Components
import { BirdiModalHeaderDanger, BirdiModalHeaderWithBackgroundColor } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import PageLayout from 'components/layouts/page/page.layout';

// States
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    joinMembershipPlanAcceptOrDeclineInviteRoutine,
    joinMembershipPlanGetInvitesRoutine
} from 'state/join-membership-plan/join-membership-plan.routines';
import {
    joinMembershipPlanInviteListSelector,
    joinMembershipPlanIsLoadingSelector,
    joinMembershipPlanUserBearerTokenSelector
} from 'state/join-membership-plan/join-membership-plan.selectors';
import { membershipRegistrationValidateInviteRoutine } from 'state/membership-registration/membership-registration.routines';
import { joinMembershipInvitationPlanStatusMap } from 'state/membership/membership.helpers';

// Interfaces and types
import { MyCaregiversCardProps } from 'types/family-management';
import {
    JoinMembershipPlanAcceptOrDeclineInvitePayload,
    JoinMembershipPlanInvitationData,
    JoinMembershipPlanInvitationResponse
} from 'types/join-membership-plan';

// Utils
import { lowercaseAndCapitalize } from 'util/cart';
import { calculateAge, parseSecureDate } from 'util/date';
import { getPhoneNumber } from 'util/globalVariables';

// HOC
import withUnauthenticatedSessionExpiration from 'hoc/withUnauthenticatedSessionExpiration';

// Styles
import './join-membership-plan-welcome.style.scss';

/**
 * Helper function to translate invite from Membership to be used inside the MyCaregiver card.
 * @param accountStatus number
 * @returns MyCaregiversCardProps['accountStatus']
 */
export const getMyCaregiverAccountStatus = (accountStatus: number): MyCaregiversCardProps['accountStatus'] => {
    const accountStatusLabel = joinMembershipInvitationPlanStatusMap.get(accountStatus);

    switch (accountStatusLabel) {
        case 'pending':
            return 'responsePending';
        case 'active':
            return 'accepted';
        case 'partialAccess':
            return 'accepted';
        default:
            return accountStatusLabel as MyCaregiversCardProps['accountStatus'];
    }
};

const JoinMembershipPlanWelcomePage = ({
    location,
    data
}: {
    location: Location;
    data: GatsbyTypes.JoinMembershipPlanWelcomeGetLanguageAndImageDataQuery;
}) => {
    // General
    const { whiteSkyBackground } = data;
    const modalErrorText = 'modals.joinMembershipPlanAcceptOrDeclineFailure';

    // Hooks
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // States
    const [inviteData, setInviteData] = useState<JoinMembershipPlanInvitationData | null>(null);
    const [inviteeAge, setInviteeAge] = useState<number | null>(null);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // Selectors
    const joinMembershipPlanBearerToken = useSelector(joinMembershipPlanUserBearerTokenSelector);
    const inviteList = useSelector(joinMembershipPlanInviteListSelector);
    const isLoadingSelector = useSelector(joinMembershipPlanIsLoadingSelector);

    useEffect(() => {
        dispatch(joinMembershipPlanGetInvitesRoutine.trigger());
    }, [dispatch]);

    /**
     * Ticket: DRX-2474
     * This useEffect is being used to avoid update the invite list during the
     * Membership Invite Verification.
     *
     * During the Accept invite process, we set the isBusy to true while we accept
     * the invite. After finishing the Accept process, we start the Membership Invite
     * Verification. If success, we need to update the inviteList for the next page
     * by triggering the joinMembershipPlanGetInvitesRoutine, but we don't want to
     * update the page list to avoid flickering, and we don't do this routine
     * in the next page to avoid another loading process.
     */

    useEffect(() => {
        if (!isBusy && inviteList && inviteList.length > 0) {
            /**
             * Ticket DRX-3196
             * As the page expects just one item inside the invite object and the user
             * can receive more than one invite, we are showing just the last received invite.
             */
            setInviteData(inviteList[inviteList.length - 1]);
            setInviteeAge(calculateAge(inviteList[inviteList.length - 1].recipientDateOfBirth));
        }
    }, [inviteList, isBusy]);

    useEffect(() => {
        const hasInvite = inviteList && inviteList.length > 0;
        const acceptedInvite = inviteList.find((accepted) => accepted.status === 1);
        const hasAcceptedInvite = acceptedInvite !== undefined;
        const hasWebAccount = acceptedInvite && acceptedInvite.hasWebAccount;

        if (!isLoadingSelector) {
            switch (true) {
                case !hasInvite:
                    navigate('/no-invitations-available');
                    break;
                case hasAcceptedInvite && inviteeAge && inviteeAge >= 18 && !hasWebAccount:
                    dispatch(
                        membershipRegistrationValidateInviteRoutine.trigger({
                            validateInviteData: {
                                firstName: acceptedInvite.recipientFirstName,
                                lastName: acceptedInvite.recipientLastName,
                                birthDate: parseSecureDate(acceptedInvite.recipientDateOfBirth),
                                email: acceptedInvite.recipientEmailAddress
                            },
                            onSuccess: () => navigate('/join-membership-plan/setup-account'),
                            onFailure: () => navigate('/no-invitations-available')
                        })
                    );
                    break;
                default:
                    setIsLoading(false);
                    break;
            }
        }
    }, [dispatch, isLoadingSelector, inviteList, inviteeAge]);

    useEffect(() => {
        if (!joinMembershipPlanBearerToken) {
            navigate('/link-expired?flow=join-membership-plan');
        }
    }, [joinMembershipPlanBearerToken]);

    const handleShowErrorModal = useCallback(
        (
            inviteId?: JoinMembershipPlanAcceptOrDeclineInvitePayload['inviteId'],
            status?: JoinMembershipPlanAcceptOrDeclineInvitePayload['status']
        ) => {
            dispatch(
                openModal({
                    showClose: false,
                    size: 'lg',
                    type: 'danger',
                    headerContent: <BirdiModalHeaderDanger headerText={t(`${modalErrorText}.title`)} icon="alert" />,
                    bodyContent: (
                        <BirdiModalContentAlt
                            subTitle={t(`${modalErrorText}.body`)}
                            note={`${t(`${modalErrorText}.footNoteText`, {
                                phoneNumber: getPhoneNumber({ isEnd: true })
                            })} `}
                        />
                    ),
                    ctas: [
                        {
                            label: t(`${modalErrorText}.buttonText`),
                            variant: 'primary',
                            onClick: () => {
                                setIsBusy(true);
                                dispatch(
                                    joinMembershipPlanAcceptOrDeclineInviteRoutine.trigger({
                                        inviteId,
                                        status,
                                        onSuccess: () => {
                                            setIsBusy(false);
                                            dispatch(closeModal({}));
                                            dispatch(joinMembershipPlanGetInvitesRoutine.trigger());
                                        },
                                        onFailure: () => {
                                            setIsBusy(false);
                                            handleShowErrorModal(inviteId, status);
                                        }
                                    })
                                );
                            },
                            dataGALocation: 'JoinMembershipPlanAcceptOrDeclineInvite'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    const handleAcceptOrDeclineInvite = useCallback(
        (
            inviteId: JoinMembershipPlanAcceptOrDeclineInvitePayload['inviteId'],
            status: JoinMembershipPlanAcceptOrDeclineInvitePayload['status']
        ) => {
            setIsBusy(true);
            if (status === 'DECLINE') {
                setIsBusy(false);
                dispatch(
                    openModal({
                        showClose: false,
                        type: 'danger',
                        size: 'lg',
                        headerContent: (
                            <BirdiModalHeaderWithBackgroundColor
                                icon="alert"
                                type="danger"
                                headerText={t('modals.joinMembershipPlan.decline.title')}
                            />
                        ),
                        bodyContent: <BirdiModalContentAlt subTitle={t('modals.joinMembershipPlan.decline.body')} />,
                        ctas: [
                            {
                                label: t('modals.joinMembershipPlan.decline.ctas.cancel'),
                                variant: 'link',
                                onClick: () => {
                                    dispatch(closeModal({}));
                                },
                                dataGALocation: 'JoinMembershipPlan'
                            },
                            {
                                label: t('modals.joinMembershipPlan.decline.ctas.decline'),
                                variant: 'primary',
                                onClick: () => {
                                    dispatch(closeModal({}));
                                    setIsBusy(true);
                                    dispatch(
                                        joinMembershipPlanAcceptOrDeclineInviteRoutine.trigger({
                                            inviteId,
                                            status,
                                            onSuccess: () => {
                                                setIsBusy(false);
                                                dispatch(joinMembershipPlanGetInvitesRoutine.trigger());
                                            },
                                            onFailure: () => {
                                                setIsBusy(false);
                                                handleShowErrorModal(inviteId, status);
                                            }
                                        })
                                    );
                                },
                                dataGALocation: 'FamilyAccountProfile'
                            }
                        ]
                    })
                );
            } else if (status === 'GRANT') {
                dispatch(
                    joinMembershipPlanAcceptOrDeclineInviteRoutine.trigger({
                        inviteId,
                        status,
                        onSuccess: () => {
                            dispatch(
                                joinMembershipPlanGetInvitesRoutine.trigger({
                                    onSuccess: (response: JoinMembershipPlanInvitationResponse) => {
                                        const acceptedInvite = response.invites.find(
                                            (accepted) => accepted.status === 1
                                        );

                                        if (acceptedInvite) {
                                            if ((inviteeAge && inviteeAge < 18) || acceptedInvite.hasWebAccount) {
                                                setIsBusy(false);
                                                setInviteData(acceptedInvite);
                                            } else {
                                                dispatch(
                                                    membershipRegistrationValidateInviteRoutine.trigger({
                                                        validateInviteData: {
                                                            firstName: acceptedInvite.recipientFirstName,
                                                            lastName: acceptedInvite.recipientLastName,
                                                            birthDate: parseSecureDate(
                                                                acceptedInvite.recipientDateOfBirth
                                                            ),
                                                            email: acceptedInvite.recipientEmailAddress
                                                        },
                                                        onSuccess: () => {
                                                            navigate('/join-membership-plan/setup-account');
                                                        },
                                                        onFailure: () => navigate('/no-invitations-available')
                                                    })
                                                );
                                            }
                                        }
                                    },
                                    onFailure: () => handleShowErrorModal()
                                })
                            );
                        },
                        onFailure: () => {
                            setIsBusy(false);
                            handleShowErrorModal();
                        }
                    })
                );
            }
        },
        [dispatch, handleShowErrorModal, t, inviteList, inviteeAge]
    );

    function getPrimaryTextContent(status: number) {
        const statusName = getMyCaregiverAccountStatus(status);

        switch (statusName) {
            case 'accepted':
                return (
                    <>
                        <p>{t('pages.profile.membership.setupMembership.initialGreeting')}</p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t('pages.profile.membership.setupMembership.initialGreetingDescription', {
                                        membershipOwner: lowercaseAndCapitalize(
                                            `${inviteList[0]?.senderFirstName} ${inviteList[0]?.senderLastName}` || ''
                                        )
                                    }),
                                    {
                                        allowedTags: ['strong']
                                    }
                                )
                            }}
                        />
                    </>
                );
            case 'declined':
                return <p>{t('pages.joinMembershipPlan.welcome.declineMessage.firstLine')}</p>;
            default:
                return (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                t('pages.joinMembershipPlan.welcome.text', {
                                    memberName: lowercaseAndCapitalize(
                                        `${inviteList[0]?.senderFirstName} ${inviteList[0]?.senderLastName}` || ''
                                    )
                                }),
                                {
                                    allowedTags: ['strong']
                                }
                            )
                        }}
                    />
                );
        }
    }

    function getSecondaryTextContent(status: number) {
        const statusName = getMyCaregiverAccountStatus(status);

        switch (statusName) {
            case 'accepted':
                return <></>;
            case 'declined':
                return <p>{t('pages.joinMembershipPlan.welcome.declineMessage.secondLine')}</p>;
            default:
                return (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                t('pages.joinMembershipPlan.welcome.secondaryText', {
                                    memberName: lowercaseAndCapitalize(
                                        `${inviteList[0]?.senderFirstName} ${inviteList[0]?.senderLastName}` || ''
                                    )
                                }),
                                {
                                    allowedTags: ['strong']
                                }
                            )
                        }}
                    />
                );
        }
    }

    function getDisclaimerTextContent(status: number) {
        const statusName = getMyCaregiverAccountStatus(status);

        switch (statusName) {
            case 'accepted':
                return <></>;
            case 'declined':
                return <></>;
            case 'expired':
                return <></>;
            default:
                return (
                    <div className="disclaimer-content">
                        <p>{t('pages.joinMembershipPlan.welcome.disclaimerText')}</p>
                    </div>
                );
        }
    }

    return (
        <PageLayout
            headerImage={whiteSkyBackground}
            headerImageClassName="welcome-background"
            pageContentClassName="welcome-wrapper"
            metaData={{ nodeTitle: t('pages.joinMembershipPlan.welcome.title') }}
            suppressApplicationPage={true}
            style={{ backgroundColor: '#FFFFFF' }}
        >
            <div className="welcome">
                <Container fluid="xl">
                    <Row className="justify-content-lg-center">
                        <Col xs={12} md={12} lg={8}>
                            <PageSectionHeader
                                eyebrowText={t('pages.joinMembershipPlan.welcome.eyebrowText')}
                                title={t(`pages.joinMembershipPlan.welcome.title`)}
                            />
                        </Col>
                        <Col xs={12} md={12} lg={8}>
                            {isLoading ? (
                                <div className="info-content">
                                    <p>Loading...</p>
                                </div>
                            ) : (
                                <>
                                    <div className="info-content">
                                        {getPrimaryTextContent(inviteList[0]?.status || 0)}
                                        {getSecondaryTextContent(inviteList[0]?.status || 0)}
                                    </div>
                                    <div className="family-member-card-list">
                                        {inviteData && (
                                            <div className={getMyCaregiverAccountStatus(inviteData.status)}>
                                                <MyCaregiversCard
                                                    caregiverName={`${inviteData.recipientFirstName} ${inviteData.recipientLastName}`}
                                                    lastUpdateDate={format(
                                                        new Date(inviteData.requestedDate),
                                                        'MM/dd/yyyy'
                                                    )}
                                                    accountStatus={getMyCaregiverAccountStatus(inviteData.status)}
                                                    isMembership={true}
                                                    isBusy={isBusy}
                                                    onAccept={() =>
                                                        handleAcceptOrDeclineInvite(inviteData.invitationId, 'GRANT')
                                                    }
                                                    onDecline={() =>
                                                        handleAcceptOrDeclineInvite(inviteData.invitationId, 'DECLINE')
                                                    }
                                                    hideActions={['accepted', 'declined'].includes(
                                                        getMyCaregiverAccountStatus(inviteData.status)
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    {getDisclaimerTextContent(inviteList[0]?.status || 0)}
                                </>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </PageLayout>
    );
};

export default withUnauthenticatedSessionExpiration(JoinMembershipPlanWelcomePage, 'join-membership-plan');

export const query = graphql`
    query JoinMembershipPlanWelcomeGetLanguageAndImageData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        whiteSkyBackground: file(relativePath: { eq: "assets/images/feather-background-white.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        allNodeApplicationPage {
            nodes {
                title
                id
                drupal_internal__nid
                relationships {
                    field_application_page_content {
                        ...supportedParagraphs
                    }
                }
                path {
                    langcode
                    alias
                }
            }
        }
    }
`;
